import React from "react";
import { Link } from "react-router-dom";

export function StudyTitle(props) {
	const s = props.study;
	const hasAccess = props.hasAccess;

	if (!hasAccess) {
		return s.title;
	}

	return (
		<Link to={"/studies/" + s.catalogID} className="link-dark">
			{s.title}
		</Link>
	);
}
