import React, { useState, useEffect } from "react";

import { useNavigate, useLocation } from "react-router";
import { useAuth } from "../authentication/AuthProvider";
import { Link, useSearchParams } from "react-router-dom";

export function Login() {
	let navigate = useNavigate();
	let location = useLocation();
	let auth = useAuth();

	const [emlAddress, setEml] = useState("");
	const [pwd, setPwd] = useState("");
	const [isNewPwd, setIsNewPwd] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const address = searchParams.get("e");
		if (address) {
			setEml(address);
			searchParams.delete("e");
		}

		const isNewPwd = searchParams.get("isreset");
		if (isNewPwd) {
			setIsNewPwd(true);
			searchParams.delete("isreset");
		}

		setSearchParams(searchParams);
	}, [searchParams, setSearchParams, setEml, setIsNewPwd]);

	let from = location.state?.from?.pathname || "/";

	function handleSubmit(event) {
		event.preventDefault();

		auth.signin(emlAddress, pwd, (user) => {
			// Send them back to the page they tried to visit when they were
			// redirected to the login page. Use { replace: true } so we don't create
			// another entry in the history stack for the login page.  This means that
			// when they get to the protected page and click the back button, they
			// won't end up back on the login page, which is also really nice for the
			// user experience.
			if (!user.jwt) {
				navigate("/loginconfirmation");
				return;
			}

			navigate(from, { replace: true });
		});
	}

	function onChangeUsername(event) {
		setEml(event.target.value);
	}

	function onChangePassword(event) {
		setPwd(event.target.value);
	}

	return (
		<div className="col-lg-5">
			<div className="card shadow-lg border-0 rounded-lg mt-5">
				<div className="card-header">
					<h3 className="text-center font-weight-light my-4">
						MF Intelligencebase Login
					</h3>
				</div>
				<div className="card-body">
					<form onSubmit={handleSubmit}>
						{isNewPwd && (
							<div class="small mb-3 text-muted">
								<b>Password updated!</b> You have created a new
								password, you can now use it to login to the
								Market Forecast Intelligencebase
							</div>
						)}
						<div className="form-floating mb-3">
							<input
								className="form-control"
								name="username"
								type="email"
								placeholder="name@example.com"
								value={emlAddress}
								onChange={onChangeUsername}
								required
							/>
							<label htmlFor="inputEmail">Email address</label>
						</div>
						<div className="form-floating mb-3">
							<input
								className="form-control"
								name="password"
								type="password"
								placeholder="Password"
								value={pwd}
								onChange={onChangePassword}
								required
							/>
							<label htmlFor="inputPassword">Password</label>
						</div>
						<div className="form-check mb-3">
							<input
								className="form-check-input"
								name="inputRememberPassword"
								type="checkbox"
								value=""
							/>
							<label
								className="form-check-label"
								htmlFor="inputRememberPassword"
							>
								Remember Password
							</label>
						</div>
						<div className="d-flex align-items-center justify-content-between mt-4 mb-0">
							<Link to="/forgotpassword" className="small">
								Forgot password?
							</Link>
							<button type="submit" className="btn btn-dark">
								Login
							</button>
						</div>
					</form>
				</div>
				<div className="card-footer text-left py-3">
					<div className="small">
						<p>
							Welcome to the MF Intelligencebase - your platform for accessing Market Forecast studies. Explore
							comprehensive market insights and forecasts. Log in to stay informed and make data-driven decisions.
						</p>
						<ul>
							<li>
								<a 
								href="https://www.marketforecast.com/contact-us?message=Request%20login%20details%20for%20MF%20Intelligencebase%20"
								target="_blank"
								>
								Need an account? Contact us on marketforecast.com!
								</a>
							</li>
						</ul>
						<p>
							&copy; 2023 MF Intelligencebase. All rights reserved. Unauthorized use or reproduction of the content on
							this platform is strictly prohibited and may be subject to legal action.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}




 
