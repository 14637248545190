export const service = {
	ForgotPassword,
	ResetPassword,
};

function ForgotPassword(emailAddress, callback) {
	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			EmailAddress: emailAddress,
		}),
	};

	return fetch("api/access/forgotpassword", requestOptions)
		.then(handleResponse)
		.then(callback);
}

function ResetPassword(code, password, repeat, callback) {
	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			Code: code,
			NewPassword: password,
			RepeatPassword: repeat,
		}),
	};

	console.log("x");
	return fetch("api/access/resetpassword", requestOptions)
		.then(handleResponse)
		.then(callback);
}

function handleResponse(response) {
	return response.text().then((text) => {
		console.log("Handling response");
		console.log(text);

		const data = text && JSON.parse(text);
		if (response.ok) {
			return data.result;
		}

		const error = (data && data.message) || response.statusText;
		return Promise.reject(error);
	});
}
