import React from "react";

import Layout from "./layout/Layout";
import { EmptyLayout } from "./layout/EmptyLayout";

import "./template.css";

import { FetchData } from "./examples/FetchData";
import { Counter } from "./examples/Counter";

import { AuthProvider } from "./authentication/AuthProvider";
import { RequireAuth } from "./authentication/RequireAuth";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Dashboard } from "./pages/Dashboard";
import { Studies } from "./pages/Studies";
import { Study } from "./pages/Study";
import { Catalog } from "./pages/Catalog";
import { Login } from "./pages/Login";
import { LoginConfirmation } from "./pages/LoginConfirmation";
import { NotificationsProvider } from "./notifications/Notifications";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ResetPassword } from "./pages/ResetPassword";
import { NotFound } from "./pages/NotFound";
import { Metadata } from "./layout/Metadata";
import { CreatePassword } from "./pages/CreatePassword";

export default App;

function App() {
	const baseUrl = document
		.getElementsByTagName("base")[0]
		.getAttribute("href");

	return (
		<div>
			<Metadata />
			<NotificationsProvider>
				<BrowserRouter basename={baseUrl}>
					<AuthProvider>
						<Routes>
							<Route
								element={
									<RequireAuth>
										<Layout
											fullScreen={true}
											collapsed={true}
										/>
									</RequireAuth>
								}
							>
								<Route
									path="studies/:catalogID"
									element={<Study />}
								/>
							</Route>
							<Route
								element={
									<RequireAuth>
										<Layout />
									</RequireAuth>
								}
							>
								<Route path="/" element={<Dashboard />} />
								<Route path="studies" element={<Studies />} />
								<Route path="catalog" element={<Catalog />}>
									<Route
										path=":tagID"
										element={<Catalog />}
									/>
								</Route>

								<Route path="/counter" element={<Counter />} />
								<Route
									path="/fetch-data"
									element={<FetchData />}
								/>
							</Route>
							<Route element={<EmptyLayout />}>
								<Route path="/login" element={<Login />} />
								<Route
									path="/loginconfirmation"
									element={<LoginConfirmation />}
								/>
								<Route
									path="/forgotpassword"
									element={<ForgotPassword />}
								/>
								<Route
									path="/resetpassword"
									element={<ResetPassword />}
								/>
								<Route
									path="createpassword"
									element={<CreatePassword />}
								/>
							</Route>
							<Route
								element={
									<RequireAuth>
										<Layout />
									</RequireAuth>
								}
							>
								<Route path="*" element={<NotFound />}></Route>
							</Route>
						</Routes>
					</AuthProvider>
				</BrowserRouter>
			</NotificationsProvider>
		</div>
	);
}
