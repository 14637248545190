import React, { createContext, useContext } from "react";
import messages from "./messages.json";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

let NotificationsContext = createContext({});

export function useNotifications() {
	return useContext(NotificationsContext);
}

export function NotificationsProvider({ children }) {
	let opts = {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	};

	let success = (msg) => toast.success(getDisplay(msg), opts);
	let warn = (msg) => toast.warn(getDisplay(msg), opts);
	let error = (msg) => toast.error(getDisplay(msg), opts);

	let promise = (promise) =>
		toast.promise(promise, {
			pending: {
				render() {
					return "loading...";
				},
				icon: false,
			},
			success: {
				render() {
					return "Success";
				},
				// other options
				icon: "🟢",
			},
			error: {
				render({ data }) {
					return getDisplay(data);
				},
			},
		});

	const defaultMsg = "default";
	let getDisplay = (msg) => {
		let disp = messages[msg];
		if (!disp) {
			disp = messages[defaultMsg];
		}

		if (!disp) {
			return msg;
		}

		return disp;
	};

	let values = { success, warn, error, promise };
	return (
		<NotificationsContext.Provider value={values}>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			{children}
		</NotificationsContext.Provider>
	);
}
